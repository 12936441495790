import React, { useState, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../data/state';
import NavbarItem from '../NavbarItem/NavbarItem';
import {User} from '../../Models/User';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './MyNavbar.css';
import { useNotification } from "../../hooks/useNotification";

const navItems = [
  {
    id: 2,
    title: 'Services',
    locationHref: '/services',
    notification: false
  },
  {
    id: 1,
    title: 'Tickets',
    locationHref: '/list-tickets',
    notification: false,
  },
  {
    id: 3,
    title: 'Balance',
    locationHref: '/list-payment-request',
    notification: false
  },
  {
    id: 4,
    title: 'Locations',
    locationHref: '/list-location',
    notification: false
  },
  {
    id: 5,
    title: 'Users',
    locationHref: '/list-user',
    notification: false
  }
];

const MyNavbar: React.FC = () =>{
  const { state, dispatch } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  const [navBarItem, setNavBarItem] = useState<any []>(navItems);
  const [userPermission, setUserPermission] = useState(state.permissions);

  const { data } = useNotification();

  useEffect(() => {

  },[])

  useEffect(() => {
    let navBarItemCopy = navBarItem.map((value) => {
      if(value.title == "Tickets"){
        value.notification = state.notification;
        return value;
      } else {
        return value;
      }
    })
    setNavBarItem([...navBarItemCopy])
  },[data])

  let history = useHistory();

  const _handleClickLogOut = async() => {
    const tempUser: User ={
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
    }
    await dispatch({
      type: 'set-is-loggedin',
      isLoggedin: false
    });
    await dispatch({
      type: 'set-user',
      user: tempUser
    });
    await dispatch({
      type: 'set-token',
      user: undefined
    });
    await dispatch({
      type: 'set-exist-ticket-id',
      existTicketId: false
    });
    await dispatch({
      type: 'set-ticket-id',
      ticketID: undefined
    });
    await dispatch({
      type: 'set-permissions',
      permissions: [],
    });
    history.replace('/home');
  }

  const _handleClickChangePassword = async() => {
    history.push("/reset-password");
  };

  const toggle = () => setIsOpen(!isOpen);


  return(
    <div>
      <Navbar className="my-navbar" dark expand="md">
        <div className="container-img-toggle">
          <NavbarBrand className="navbar-img" href="/">
          <div className="container-logo">
              <img src="assets/logo_white.png" className="logo-navbar"/>
              <span className="title-navbar">Company</span>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle}  className="burger"/>
        </div>
        <Collapse isOpen={isOpen} navbar>
          {
            state.permissions !== undefined &&
            <Nav className="ml-auto" navbar>
            {navItems.map((element) => {
              if(state.permissions.includes(element.id)) {
                return(
                  <NavbarItem
                    itemTitle={element.title}
                    itemLoc={element.locationHref}
                    key={element.id}
                    toggle={toggle}
                    notification={element.notification}
                  />
                );
              }
            })}
            </Nav>
          }
            <UncontrolledDropdown >
                <DropdownToggle
                  caret
                  color="primary"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    className="photo"
                    alt="..."
                    src="assets/login/default-avatar.png"
                  />
                </DropdownToggle>
                <DropdownMenu right >
                  <NavLink onClick={_handleClickChangePassword}>
                    <DropdownItem >Change Password</DropdownItem>
                  </NavLink>
                  <NavLink onClick={_handleClickLogOut}>
                    <DropdownItem >Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
