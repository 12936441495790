import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./CreatePrice.css";

import Loading from "../../components/Loading/Loading";
import MyTitle from "../../components/MyTitle/MyTitle";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import MyModal from "../../components/MyModal/MyModal";

const CreatePrice: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [companiesSubServiceId, setCompaniesSubServiceId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [price, setPrice] = useState("");

  const [isUpdateCreatePrice, setIsUpdateCreatePrice] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const location: any = useLocation();
  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Price",
      modalBody: "Price created succesfully",
    },
    {
      modalTitle: "Price",
      modalBody: "Choose a status to Unity Quantity",
    },
    {
      modalTitle: "Price",
      modalBody: "Price updated succesfully",
    },
  ];

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.priceId != "") {
        setIsUpdateCreatePrice(true);
        setCompaniesSubServiceId(location.state.companiesSubServiceId);
        setPriceId(location.state.priceId);

        setPrice(location.state.price);
      } else {
        setCompaniesSubServiceId(location.state.companiesSubServiceId);
        setPriceId(location.state.priceId);
      }
    }
  }, []);

  const onSubmitFormCompanyService = (e: any) => {
    e.preventDefault();
    if (isUpdateCreatePrice) {
      updatePrice();
    } else {
      addPrice();
    }
  };

  const addPrice = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        CompaniesSubServicesID: companiesSubServiceId,
        Price: price,
      };
      const request = await server.post(
        "/company/prices",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const updatePrice = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Price: price,
      };
      const request = await server.put(
        `/company/prices/${priceId}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangePrice = (e: any) => {
    setPrice(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      <MyTitle title="Price" />
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreatePrice ? "Update a Price" : "Create a Price"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyService}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input">Price: </span>
                <input
                  className="input-form-create"
                  type="number"
                  name="price"
                  id="price"
                  onChange={onChangePrice}
                  autoComplete="off"
                  placeholder="Price..."
                  value={price}
                  required
                />
              </div>

              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePrice;
