import { useState } from "react";
import "./MySectionSignUp.css";
import { useHistory } from "react-router-dom";

function MySectionSignUp() {
  const [inputEmail, setInputEmail] = useState('');
  let history = useHistory();

  const handleChange = (e:any) =>{
    setInputEmail(e.target.value);
  }
  const handleEmail = () =>{
    window.scroll(0,0);
    setInputEmail('');
    history.replace({
      pathname: '/signup',
      state: {inputEmail}
    })
  }

  return (
    <>
      <div className="section-sign-up">
        <div className="container-section-sign-up">
          <h2 className="title-section-sign-up">
            Sign Up For <b>Free!</b>
          </h2>
          <p className="text-section-sign-up">
            Sign up for special offers and reminders for all home services
            needed throughout the year
          </p>

          <div className="form">
            <img src="assets/howit_works/email2.png" alt="icon email" className="envelope-icon-input" />
            <input
              type="email"
              name="email"
              id="email"
              value={inputEmail}
              onChange={(e) => handleChange(e)}
              className="input-signup"
              placeholder="Your email..."
            />
            <button
              className="btn-signup"
              onClick={() => handleEmail()}
            >
              <img src="assets/howit_works/email.png" alt="icon email" className="envelope-btn" />
              <span className="text-in-btn">SIGN UP</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MySectionSignUp;
