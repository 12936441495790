import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import MyTitle from "../../components/MyTitle/MyTitle";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import MyList from "../../components/MyList/MyList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import { AppContext } from "../../data/state";

import "./Calendar.css";
import server from "../../api/server";

const Calendar: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [datePicker, setDatePicker] = useState("");
  const [companyServiceID, setCompanyServiceID] = useState("");

  const [dataTable, setDataTable] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyServiceID(location.state.companyServiceID);
    }
  }, []);

  const onSubmitFormCalendar = (e: any) => {
    e.preventDefault();
    loadDataCalendar();
  };

  const loadDataCalendar = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const data = {
        CompanyServiceID: companyServiceID,
        Date: moment(datePicker).format("YYYY-MM-DD"),
      };

      
      const request = await server.post(
        "/company/company-calendar/calendar",
        JSON.stringify(data),
        { headers }
      );
      const response = request.data;

      let listData = response.data;
      if (listData.length != 0) {
        let table: any = [];
        let content = [];

        content = listData.map((value) => {
          return formatTime(value.hour);
        });

        let hour = {
          data: { title: "Hour", content },
          button: false,
        };

        content = listData.map((value) => {
          return formatHours(value.duration);
        });

        let duration = {
          data: { title: "Duration", content },
          button: false,
        };

        content = listData.map((value) => {
          return value.capacity;
        });

        let capacity = {
          data: { title: "Capacity", content },
          button: false,
        };

        content = listData.map((value) => {
          return value.available;
        });

        let available = {
          data: { title: "Available", content },
          button: false,
        };

        content = listData.map((value) => {
          return value.events;
        });

        let events = {
          data: { title: "Events", content },
          button: false,
        };

        content = listData.map((value) => {
          return value.scheduled;
        });

        let scheduled = {
          data: { title: "Scheduled", content },
          button: false,
        };

        table.push(hour);
        table.push(duration);
        table.push(capacity);
        table.push(available);
        table.push(events);
        table.push(scheduled);

        setDataTable(table);
      } else {
      }
      window.scroll(0, 600);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeDatePicker = (value) => {
    setDatePicker(value);
  };

  const formatTime = (time: any) => moment(time, "HHmmss").format("hh:mm A");
  const formatHours = (time: any) => {
      let timeArray: any [] = time.split(":");
      timeArray.splice(timeArray.length-1,1);
      return timeArray.join(":") + " hrs";
  };

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  const addEvent = () =>{
    history.push({
      pathname: '/create-event'
    });
    window.scrollTo(0, 0);
  }
  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title={"Calendar"} />
      <div className="container-btn-price">
        <BtnAddList label="Add Event" onClick={addEvent} />
      </div>
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">Calendar</h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCalendar}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input">Choose a date: </span>
                <MyDatePicker
                  setDatePicker={onChangeDatePicker}
                  dateDefault={datePicker}
                />
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
          
        </div>
      </section>
      {dataTable.length !== 0 && (
        <>
          <div className="section-list">
            <MyList data={dataTable} />
          </div>
        </>
      )}
    </>
  );
};

export default Calendar;
