import "./ListItem.css";

function ScheduleItem({ data, button }) {
  return (
    <div className="schedule-container">
      <div className="schedule-header">
        <p>
          <strong> {button ? "" : data?.title} </strong>
        </p>
      </div>
      {button ? (
        <div className="schedule-header">
          {data?.content.map((value: any, index: number) => {
            return (
              <div className="schedule-buttons-container" key={index}>
                {value.button.map((item: any, index: number) => {
                  return (
                    <button
                      onClick={item.onPressed}
                      key={index}
                      className={`${
                        item.label == "DEACTIVE" ? "deactive-btn-user" : ""
                      }`}
                    >
                      {item.label}
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="schedule-header">
          {
            data?.content.map((item: string, index: number) => (
              <div
                className={`${
                  data.title == "Ticket Number" || data.title == "Price" ?
                  "text-container id-and-price" : "text-container text-container-normal"
                }`}
                key={index}
              >
                <span>{item}</span>
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
}

export default ScheduleItem;
