import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import server from "../api/server";
import { AppContext } from "../data/state";

export const useNotification = () => {
  const [data, setData] = useState();
  const { state, dispatch } = useContext(AppContext);

  const location = useLocation();

  useEffect(() => {
    checkNotification();
    let path = location.pathname;
    if (path == "/list-tickets") {
      if(state.notification){
          deactiveNotification();
      }
    }
  }, [location]);

  const checkNotification = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/company/company/notifications", {
        headers,
      });
      const response = request.data.data;
      dispatch({
        type: "set-notification",
        notification: response.notifications,
      });
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const deactiveNotification = async () => {
    try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          };
        const request = await server.delete('/company/company',{headers})
        const response = request.data.data;
        dispatch({
            type: 'set-notification',
            notification: response.notifications
        })
        setData(response)
    } catch (error) {
        console.error(error)
    }
  };

  return {
    data,
  };
};
