import ListItem from "../ListItem/ListItem";
import "./MyList.css";
const MyList = ({data}) => {

  return (
    <>
      <div className="my-list-container-table">
        <section className="my-list-container-schedule">
          {data.map((value: any, index:number) => {
            return <ListItem button={value.button} data={value.data} key={index}/>;
          })}
        </section>

      </div>
    </>
  );
};

export default MyList;
