import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  NavItem,
  NavLink,
} from 'reactstrap';
import { AppContext } from '../../data/state';
import './NavbarItem.css';

interface NavbarItemProps {
  itemTitle: string,
  itemLoc: string,
  toggle: Function,
  notification: Boolean
}; 

const NavbarItem: React.FC<NavbarItemProps> = ({itemTitle='',itemLoc='', toggle, notification}) =>{
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();
  const [notificationBell, setNotificationBell] = useState<Boolean>(notification);
  useEffect(() => {
    setNotificationBell(notification);
  },[notification])
  return (
      <NavItem className="nav-item" onClick={() => {
        if(state.isMobile){
          toggle();
        }
          history.push(itemLoc)
        }}>
        <NavLink>{itemTitle}</NavLink>
        {notificationBell &&
          <div className="badge-item">
            <img src="assets/notification/bell.svg" alt="bell"/>
          </div>
        }
      </NavItem>
  );
}

export default NavbarItem;