import { useContext, useEffect } from "react"
import { AppContext } from "../data/state";

export const useMobile = () => {
  const { state, dispatch } = useContext(AppContext);

    const eventSetMobile = () => {
      if(window.innerWidth < 992){
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    useEffect(() => {
        window.addEventListener("resize",eventSetMobile);
        return () => {
          window.removeEventListener("resize",eventSetMobile);
        }
      },[])

    const setIsMobile = async (value) => {
        await dispatch({
            type: 'set-is-mobile',
            isMobile: value,
        })
    }

    return null;
}